@import '../../../styles/index';

.badge {
  align-items: center;
  background-color: $color-white--100;
  border-radius: 0.25rem;
  border: 1px solid $color-surrogate--10;
  color: $color-surrogate--80;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  height: 1.25rem;
  margin-left: auto;
  padding: 0 0.5rem;
  user-select: none;
}

.shortcut {
  margin-left: auto;
}

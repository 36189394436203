@import '../../../styles/index';

@keyframes pulse-error {
  0% {
    box-shadow: 0 0 0 0 rgba($color-red--100, 0.2);
  }

  70% {
    box-shadow: 0 0 0 0.625rem rgba($color-red--100, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($color-red--100, 0);
  }
}

@keyframes pulse-success {
  0% {
    box-shadow: 0 0 0 0 rgba($color-green--100, 0.2);
  }

  70% {
    box-shadow: 0 0 0 0.625rem rgba($color-green--100, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($color-green--100, 0);
  }
}

@keyframes pulse-warning {
  0% {
    box-shadow: 0 0 0 0 rgba($color-yellow--100, 0.2);
  }

  70% {
    box-shadow: 0 0 0 0.625rem rgba($color-yellow--100, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba($color-yellow--100, 0);
  }
}

.base {
  align-items: center;
  appearance: none;
  border: none;
  border-radius: 0.25rem;
  color: $color-black--80;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  gap: 0.25rem;
  height: 1.25rem;
  opacity: 0;
  padding: 0 0.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  transition: opacity 0.3s ease-in-out;
  user-select: none;

  &.is-clickable {
    cursor: pointer;
  }

  &.is-visible {
    opacity: 1;
  }

  &.error {
    background-color: $color-red--05;
    box-shadow: 0 0 0 0 rgba($color-red--100, 1);
    color: $color-red--100;

    &.is-visible {
      animation: pulse-error 2s;
    }
  }

  &.success {
    background-color: $color-green--05;
    box-shadow: 0 0 0 0 rgba($color-green--100, 1);
    color: $color-green--100;

    &.is-visible {
      animation: pulse-success 2s;
    }
  }

  &.warning {
    background-color: $color-yellow--05;
    box-shadow: 0 0 0 0 rgba($color-yellow--100, 1);
    color: $color-yellow--100;

    &.is-visible {
      animation: pulse-warning 2s;
    }
  }
}

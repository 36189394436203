@import '../../../styles/index';

.badge {
  align-items: center;
  border: 1px solid $color-surrogate--10;
  border-radius: 0.25rem;
  color: $color-surrogate--80;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  height: 1.25rem;
  margin-left: 0.25rem;
  padding: 0 0.5rem;
  user-select: none;
}

.indicator {
  color: $color-surrogate--100;
  margin-left: auto;
}

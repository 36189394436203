@import '../../../styles/index';

.base {
  align-items: center;
  color: rgba($color-black--100, 0.75);
  display: inline-flex;
  font-weight: $font-weight-medium;
  gap: 0.125rem;
}

.key {
  align-items: center;
  background-color: rgba($color-black--100, 0.125);
  border-radius: $border-radius-small;
  display: inline-flex;
  font-family: 'Euclid Enercity';
  font-size: 0.625rem;
  height: 1.125rem;
  justify-content: center;
  line-height: 1;
  min-width: 1.125rem;
  padding: 0 0.25rem;
  text-transform: uppercase;
}

.chain-indicator {
  font-size: 0.75rem;
  margin: 0 0.125rem 0.0625rem;
}

@import '../../../styles/index';

@keyframes overlay-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.base {
  z-index: 100;

  [cmdk-overlay] {
    animation: overlay-fade-in $duration-default;
    background: rgba(0, 2, 16, 0.68);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  [cmdk-dialog] {
    align-items: flex-start;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    pointer-events: none !important;
    position: fixed;
    right: 0;
    top: 0;
  }

  [cmdk-root] {
    background: $color-white--100;
    border: 1px solid $color-black--10;
    border-radius: 0.75rem;
    box-shadow: $shadow--small;
    font-family: 'Euclid Enercity';
    font-weight: $font-weight-regular;
    margin-top: 20vh;
    max-width: 640px;
    overflow: hidden;
    pointer-events: all !important;
    transition: transform $duration-short ease;
    width: 100%;
    z-index: 2;
  }

  [cmdk-input] {
    background: $color-white--100;
    border-radius: 0;
    border: none;
    color: $color-surrogate--100;
    font-size: $font-size-18;
    height: 4rem;
    outline: none;
    padding: 0;
    width: 100%;

    &::placeholder {
      color: $color-surrogate--40;
    }
  }

  [cmdk-list] {
    height: var(--cmdk-list-height);
    margin: 0.5rem 0;
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    padding: 0 0.5rem;
    transition: height $duration-short;
  }

  [cmdk-item] {
    align-items: center;
    background-color: transparent;
    border-radius: 0.375rem;
    color: $color-black--100;
    content-visibility: auto;
    cursor: pointer;
    display: flex;
    font-size: $font-size-14;
    gap: 0.75rem;
    height: 2.625rem;
    padding: 0 0.75rem;
    transition-property: none;
    transition: background-color $duration-short, color $duration-short;
    user-select: none;
    will-change: background-color, color;

    &[aria-selected='true'],
    &:active {
      background-color: $color-surrogate--05;
      color: $color-surrogate--100;
    }

    &[aria-disabled='true'] {
      color: $color-black--10;
      pointer-events: none;
    }

    & > span:first-of-type {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }

  [cmdk-enercity-shortcuts] {
    display: flex;
    margin-left: auto;
    gap: 0.5rem;

    kbd {
      font-family: Arial, Helvetica, sans-serif;
      font-size: $font-size-12;
      min-width: 1.25rem;
      padding: 0.125rem 0.25rem;
      height: 1.25rem;
      border-radius: 0.25rem;
      color: $color-surrogate--100;
      background: $color-surrogate--05;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
  }

  [cmdk-separator] {
    height: 1px;
    width: 100%;
    background: $color-surrogate--05;
    margin: 0.25rem 0;
  }

  [cmdk-group-heading] {
    align-items: center;
    color: $color-surrogate--40;
    display: flex;
    font-size: $font-size-12;
    height: 2.4375rem;
    padding: 0 0.75rem;
    user-select: none;

    &:has(+ [cmdk-group-items]:empty) {
      display: none;
    }

    @supports not selector(:has(a, b)) {
      & + [cmdk-group-items]:empty::after {
        align-items: center;
        color: $color-black--10;
        content: 'Keine Elemente in dieser Kategorie';
        display: flex;
        font-size: $font-size-14;
        gap: 0.75rem;
        height: 2.625rem;
        padding: 0 0.75rem;
        pointer-events: none;
      }
    }
  }

  [cmdk-empty] {
    align-items: center;
    background-color: $color-surrogate--05;
    border-radius: 0.375rem;
    color: $color-surrogate--100;
    content-visibility: auto;
    display: flex;
    font-size: $font-size-14;
    gap: 0.75rem;
    height: 2.625rem;
    padding: 0 0.75rem;
    user-select: none;

    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
}

@import '../../../styles/index';

.item {
  justify-content: space-between;
}

.status-badge {
  background-color: $color-white--100;
  border-radius: 0.25rem;
  border: 1px solid $color-surrogate--10;
  color: $color-surrogate--80;
  display: inline-flex;
  font-size: $font-size-12;
  font-weight: $font-weight-medium;
  height: 1.25rem;
  padding: 0 0.5rem;
}

.success {
  color: $color-green--100;
  border-color: $color-green--20;
}

.warning {
  color: $color-black--60;
  border-color: $color-black--10;
}

.error {
  color: $color-red--100;
  border-color: $color-red--20;
}
